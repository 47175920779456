var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"bigBlock"},[_c('div',{staticClass:"iframeWrap",class:_vm.dualScreenInfo == 1 ? 'w-1292' : ''},[(!_vm.IsPurchase && _vm.type != 3)?_c('div',{staticClass:"lTag",class:{
        lTag_bg1: _vm.typeOne,
        lTag_bg2: _vm.typeTwo,
        lTag_bg4: _vm.typeFour,
      },on:{"click":_vm.zhifu}},[_vm._v(" "+_vm._s(_vm.type == 1 ? "购买套餐" : "购买教材")+" ")]):_vm._e(),_c('div',{staticClass:"dualScreen iconfont icon-shuangping",on:{"click":_vm.dualScreen}}),_c('div',{staticClass:"leftArrow",on:{"click":function($event){return _vm.debounce(_vm.nextPage, 200)}}},[_c('div',{staticClass:"iconfont icon-xiangyou"})]),_c('div',{staticClass:"rightArrow",on:{"click":function($event){return _vm.debounce(_vm.prePage, 200)}}},[_c('div',{staticClass:"iconfont icon-xiangzuo"})]),_c('div',{class:_vm.dualScreenInfo == 1 ? 'pdfBox' : ''},[_c('pdf',{ref:"pdf",staticClass:"pdf-item",attrs:{"page":_vm.currentPage,"src":_vm.names ? _vm.names : ''},on:{"progress":_vm.progress,"num-pages":function($event){_vm.total = $event},"loaded":_vm.loadPdfHandler}}),_c('pdf',{directives:[{name:"show",rawName:"v-show",value:(_vm.dualScreenInfo == 1),expression:"dualScreenInfo == 1"}],ref:"pdf",staticClass:"pdf-item",attrs:{"page":_vm.currentPage + 1,"src":_vm.names ? _vm.names : ''},on:{"progress":_vm.progress,"num-pages":function($event){_vm.total = $event},"loaded":_vm.loadPdfHandler}})],1),(!_vm.IsPurchase && _vm.coverInfo)?_c('div',{staticClass:"coverBox"},[_c('div',{staticClass:"tipsWrap"},[_vm._m(0),(_vm.type != 3)?_c('div',{staticClass:"tipsBtn",class:{
            tipsBtn1: _vm.typeOne,
            tipsBtn2: _vm.typeTwo,
            tipsBtn4: _vm.typeFour,
          },on:{"click":_vm.zhifu}},[_vm._v(" 立即购买 ")]):_vm._e()])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tipsBlock"},[_c('i',{staticClass:"iconfont icon-suotou"}),_vm._v("购买解锁全部教材 ")])
}]

export { render, staticRenderFns }